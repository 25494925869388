body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.label {
  font-size: 20px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#copyrightInfo {
  margin-top: 5px;
  color: #000000;
}

span {
  color: green;
  margin-left: 90px;
}

#aboutUs {
  margin-top: 30px;
}

.pageWhiteText {
  color: #a6a6a6;
}


/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height #ff0000*/
  background: -webkit-linear-gradient(#343A40, #ff8080);
   background: -webkit-gradient(linear, left top, left bottom, from(#343A40), to(#ff8080));
   background: linear-gradient(#343A40, #ff8080);
  background-color: #343A40;
}

nav {
  background-color: #343A40;
}

#attentionText {
  color: green;
  text-align: center;
}

a {
  text-decoration: none;
  color: #4d79ff;

}

a:hover {
  text-decoration: none;
  color: black;
}

a:visited {
  /*color: #ff1a1a;*/
}


a:active {
  color: #D93D4A;
}

.center {
  text-align: center;
}

.imageCenter {
   display: block;
   margin-left: auto;
   margin-right: auto;
   width: 50%;
}

.topPageText {
  color: white ;
}

.screenshotCarousel {
  height: 100%;
}

.myDivStyle {
  margin-top: 65px;
}

.myBottomItems {
  margin-left: 8px;
}

.userData {
  margin-left: 55px;
}


.jumbotron {

			/*--background-image: url(parkunsplash.jpg);  #bfbfbf*/
      background: -webkit-gradient(linear, left top, left bottom, from(#343A40), to(#ff8080));
      background: -webkit-linear-gradient(#343A40, #ff8080);
      background: linear-gradient(#343A40, #ff8080);
      background-color: #e3f2fd;
			text-align: center;
			margin-top: 0px;
      width: 100%;
      margin-bottom: 30px;
      padding: 50px;

	}



.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  /*line-height: 60px;  Vertically center the text there */
  box-shadow: 0 50vh 0 50vh #000;
  background-color: #ffe6e6;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#toggleLogin {

  padding-right: 15px;

}

#copyrightInfo {
  margin-top: 5px;
  color: #000000;
}

span {
  color: green;
  margin-left: 90px;
}

#aboutUs {
  margin-top: 30px;
}



/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method.
.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}*/


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

